import { css } from "@emotion/react"
import { graphql, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import Benefit from "../../components/common/Benefit-box/Benefit"
import Buttons from "../../components/common/button"
import CTA from "../../components/common/CTA"
import Frame from "../../components/common/frame"
import MarqueeImages from "../../components/common/MarqueeImages/MarqueeImages"
import PopUpForm from "../../components/common/PopUpForm"
import SVGIcon from "../../components/common/SVGIcon"
import Navigation from "../../components/navigation"
import Slide from "../../components/utility/slide_content"
import "../../styles/component/faq.scss"
import "../../styles/component/pricing-faq.scss"
import "../../styles/pages/roadmap.scss"
import "../../styles/pages/tech-partnership.scss"

const TechPartnership = () => {
  const [popupVisibility, setPopupVisibility] = useState(false)
  const togglePopup = () => {
    setPopupVisibility(!popupVisibility)
  }
  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a name",
    },
    {
      name: "jobtitle",
      type: "text",
      label: "Job title",
      errorMessage: "Please enter a job title",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "company",
      type: "text",
      label: "Company name",
      errorMessage: "Please enter a company name",
    },
    {
      name: "mobilephone",
      type: "phone",
      label: "Mobile number",
      errorMessage: "Please enter a mobile number",
    },
    { name: "partnership_type", type: "hidden", label: "Partnership", value: "Technology Partners" },
  ]
  // const responsive = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 2.15,
  //   },
  //   mobile: {
  //     breakpoint: { max: 767, min: 500 },
  //     items: 1.3,
  //   },
  //   mobile1: {
  //     breakpoint: { max: 500, min: 0 },
  //     items: 0.92,
  //   },
  // }

  return (
    <>
      <StaticQuery
        query={graphql`
          query techPartnership {
            SuperOps {
              pages(where: { title: "tech partnership" }) {
                title
                subtitle
                seo {
                  title
                  description
                  image {
                    url
                  }
                }
                newField {
                  ... on SuperOps_Hero {
                    tag
                    backgroundColor
                    heroHeading: heading {
                      text
                      html
                    }
                    content {
                      text
                    }
                    primaryButtonText
                    primaryButtonLink
                  }
                  ... on SuperOps_Card {
                    __typename
                    cardName
                    title
                    heading
                    text
                    subtext
                    image {
                      url
                    }
                  }
                  ... on SuperOps_CtaFeature {
                    __typename
                    ctaBox {
                      type
                      theme
                      customBackgroundColor
                      heading {
                        html
                      }
                      description {
                        html
                      }
                      primaryButtonText
                      primaryButtonLink
                      secondaryButtonText
                      secondaryButtonLink
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { seo, newField, subtitle } = page
              const hero = newField[0]
              const feedback = newField[2]
              let navBgColor = hero.backgroundColor
              navBgColor = navBgColor.slice(
                navBgColor.indexOf("#"),
                navBgColor.indexOf("#") + 7
              )

              return (
                <div className="tech-partner">
                  <Frame seo={seo} newButton>
                    <header>
                      <Navigation
                        color={navBgColor}
                        page="About us"
                        dskScroll={450}
                        newButton
                      />
                    </header>

                    <div>
                      <PopUpForm
                        visibility={popupVisibility}
                        togglePopup={val => {
                          setPopupVisibility(val)
                        }}
                        formData={formdata}
                        endpoint={process.env.HUBSPOT_PARTNERSHIP_ENDPOINT}
                        url={process.env.HUBSPOT_TECH_PARTNERS_URL}
                        formName="Tech partnerships form - Superops"
                        formTitle="Become a SuperOps Tech Partner"
                        successMsg="Submitted successfully!"
                        additionalFormProps={{IpStackData: true}}
                      />
                    </div>

                    <section
                      className="hero text-center"
                      css={css`
                        background: ${hero.backgroundColor};
                      `}
                    >
                      <Container className="Layout-container position-relative">
                          <SVGIcon
                            name="leftHand"
                            className="position-absolute left-image d-dsk down-to-up-3"
                          />
                          <SVGIcon
                            name="rightHand"
                            className="position-absolute right-image d-dsk down-to-up-3"
                          />

                          <h1 className="heading mx-auto fw-bold down-to-up-1">
                            {hero.heroHeading.text}
                          </h1>
                          <div className="p18 down-to-up-2">
                            <p className="description mx-auto">
                              {hero.content.text}
                            </p>
                          </div>
                          <Buttons
                            theme="primary-new mb50 down-to-up-3"
                            arrow
                            onClick={() => togglePopup()}
                            text={hero.primaryButtonText}
                          />{" "}
                      </Container>
                    </section>

                    <MarqueeImages heading={subtitle} distance="0" integrations/>

                    <section>
                      <Benefit
                        type="SuperOps_Benefits"
                        title={newField[1].heading[0]}
                        heading={newField[1].text}
                        icon={newField[1].image}
                        description={newField[1].subtext}
                        arrowSVG="benefitsSideArrow-2"
                      />
                    </section>

                    {/* <section className={`partners-feedback ${feedback.title.length < 3 ? "hide-arrow" : ""}`}>
                      <Container className="Layout-container">
                        <Row>
                          <Col lg={3}>
                            <div className="altv3">
                              <h2 className="side-heading fw-bold">
                                {feedback.cardName}
                              </h2>
                            </div>
                          </Col>
                          <Col lg={9}>
                            <CarouselComponent
                              responsive={responsive}
                              swipeable
                            >
                              {feedback.title.map((item, idx) => {
                                return (
                                  <div
                                    className="card-wrapper d-flex"
                                  >
                                    <div className="left">
                                      <SVGIcon
                                        name="home/quote-home"
                                        className="quote-icon"
                                      />
                                    </div>
                                    <div className="right">
                                      <div className="p16">
                                        <p className="quotes fw-600 mb24">
                                          {item}
                                        </p>
                                      </div>
                                      <div className="p16">
                                        <p className="text fw-600 m-0">
                                          {feedback.text[idx]}
                                        </p>
                                      </div>
                                      <div className="p14">
                                        <p className="subtext mb24">
                                          {feedback.subtext[idx]}
                                        </p>
                                      </div>

                                      <div className="hor-line mb30"></div>
                                      <div className="img-wrapper">
                                        <img
                                          src={feedback.image[idx].url}
                                          className="w-100"
                                          alt="partners"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </CarouselComponent>
                          </Col>
                        </Row>
                      </Container>
                    </section> */}

                    <section>
                      <Slide delay="200">
                        <CTA
                          data={[newField[3].ctaBox]}
                          className="Layout-container"
                          maxHeadingWidth="400px"
                          lgLeft={7}
                          lgRight={4}
                          rightStyles={{ alignItems: "center" }}
                          newButton
                          newDesign
                          openPopUp={() => togglePopup()}
                        />
                      </Slide>
                    </section>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default TechPartnership
